body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #eee;
  font-family: sans-serif;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  padding: 1.5rem 2.5rem 0;
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.answerOption:hover {
  background-color: #eefbfe;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: #8bc53f url(./svg/icon-check.svg) no-repeat;
  background-size: 27px;
  border-color: #8bc53f;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:30px;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.p-homepage{
  text-align: left;
  margin: 25px 50px 75px 100px;
} 

.a-link:link, .a-link:visited {
  background-color: #A1FCDF;
  border-color: #6d6d6d;
  border-style: outset;
  color: rgb(0, 0, 0);
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.a-link:hover, .a-link:active {
  background-color: #7FD8BE;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 15px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}